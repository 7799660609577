@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































.ladder-wrapper {
  .el-button {
    border-top: none;
    width: 100%;
  }
}
::v-deep .el-table--border {
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}

.info-p {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 43px;
  left: -9px;
  text-align: right;
  display: inline-block;
  width: 100%;
  text-align: right;
}
