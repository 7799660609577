@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































.table-container .wrapper {
  // max-width: 800px;
  margin: 0 auto;
  overflow-y: hidden !important;
}

.btn-wrapper {
  position: fixed;
  width: 100%;
  bottom: 15px;
  height: 40px;
  text-align: center;
  .el-button {
    margin: 0 8px;
  }
}
.el-icon-question {
  position: absolute;

  top: -1px;
  left: 51px;
}
.tip-p {
  position: absolute;
  left: -103px;
  color: red;
  bottom: -25px;
}

.info-p {
  position: absolute;
  top: 83px;
  font-size: 12px;
}
.form-wrapper {
  // padding-top: 35px;
}
