@import "~@/erp/styles/variables/variables.scss";



























































































.fixed-wrapper {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .fixed-table {
    width: 100%;
  }

  .list-wrapper {
    // border: 1px solid #eee;
    padding: 10px 10px 10px 0;
    .item {
      display: flex;
      border-bottom: 1px dashed #eee;
      padding-top: 10px;
      padding-bottom: 10px;
      align-items: center;
      .no {
        display: inline-block;
        height: 30px;
        width: 30px;
        line-height: 30px;
        border-radius: 50%;
        border: 1px solid #eee;
        text-align: center;
        margin-right: 10px;
      }
      .sub {
        flex: 1;
      }
    }
  }
}
